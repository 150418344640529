<template>
  <div class="row">
    <div class="col-md-12">
      <v-card color="transparent" class="elevation-0">
        <div class="d-flex mb-3">
          <v-btn color="primary" class="text-capitalize ml-auto" @click.prevent="openDialog('insert', '')"><i class="fas fa-user-plus mr-2"></i> Tambah Quotation</v-btn>
        </div>
          <v-data-table
              :headers="headers"
              :items="dataTable"
              item-key="id"
              :loading="loadingTable"
              loading-text="Memuat data . . ."
              class="elevation-1"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer
              @page-count="pageCount = $event"
          >
            <template v-slot:[`item.quotation.status`]="{ item }">
              <div v-if="item.quotation.status === 1">
                <v-chip 
                  class="ma-1 caption"
                  color="error"
                >
                  Belum Memilih Influencer
                </v-chip>
              </div>
              <div v-else>
                <v-chip 
                  class="ma-1 caption"
                  color="success"
                >
                  Telah Memilih Influencer
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.influencer`]="{ item }">
              <v-chip 
                class="ma-1 caption"
                color="secondary"
                v-for="influ in item.quotation_detail" :key="influ.id"
              >
                {{ influ.qty }} {{ influ.level === 'A' ? 'Nano' : 'Micro' }}
              </v-chip>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div v-if="item.quotation.status === 2">
                <v-btn
                  color="primary"
                  class="text-capitalize mr-2"
                  small
                  @click="gotToDetail(item.quotation.id)"
                >
                  <i class="fa fa-eye mr-2"></i>
                  Lihat
                </v-btn>
                <v-btn
                  color="error"
                  class="text-capitalize"
                  small
                  @click.prevent="openDialog('delete', item.quotation.id)"
                >
                  <i class="fas fa-trash mr-2"></i>
                  Hapus
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  color="warning"
                  class="text-capitalize mr-2"
                  small
                  @click.prevent="openDialog('edit', item.quotation.id)"
                >
                  <i class="fas fa-pen mr-2"></i>
                  Ubah
                </v-btn>
                <v-btn
                  color="error"
                  class="text-capitalize"
                  small
                  @click.prevent="openDialog('delete', item.quotation.id)"
                >
                  <i class="fas fa-trash mr-2"></i>
                  Hapus
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center mt-2 pt-0">
              <v-pagination
                  v-model="page"
                  :length="pageCount"
              ></v-pagination>
          </div>
          <div class="text-center pb-2">
          </div>
      </v-card>
    </div>

    <!-- DIALOG INSERT DATA -->
    <v-dialog v-model="dialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialogItem.name }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row class="pb-0 mb-0 pt-0 mt-0">
              <div class="col-md-6 pb-0 mb-0 pt-0 mt-0">
                <v-text-field
                  v-model="quoName"
                  label="Quotation Name"
                  :rules="[v => !!v || 'Tidak boleh kosong']"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-6 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="clientName"
                  :items="client"
                  item-text="label"
                  item-value="value"
                  label="Client"
                  :rules="[v => !!v || 'Client belum dipilih']"
                  outlined
                  dense
                ></v-select>
              </div>
            </v-row>
            <v-row class="pb-0 mb-0 pt-0 mt-0">
              <div class="col-md-12 pt-0 mt-0">
                <p class="pb-0 mb-0 body-1 font-weight-bold">Nano Influencer</p>
              </div>
            </v-row>
            <v-row class="pb-0 mb-0 pt-0 mt-0">
              <div class="col-md-2 pb-0 mb-0 pt-0 mt-0">
                <v-text-field
                  v-model="qtyA"
                  label="Jumlah"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="tipeA"
                  :items="ContentType"
                  item-text="label"
                  item-value="value"
                  label="Tipe Konten"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="formatA"
                  :items="filteredFormat"
                  item-text="label"
                  item-value="value"
                  label="Format Konten"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="platformA"
                  :items="platform"
                  item-text="label"
                  item-value="value"
                  label="Platform"
                  outlined
                  dense
                ></v-select>
              </div>
            </v-row>
            <v-row class="pb-0 mb-0 pt-0 mt-0">
              <div class="col-md-12 pt-0 mt-0">
                <p class="pb-0 mb-0 body-1 font-weight-bold">Micro Influencer</p>
              </div>
            </v-row>
            <v-row class="pb-0 mb-0 pt-0 mt-0">
              <div class="col-md-2 pb-0 mb-0 pt-0 mt-0">
                <v-text-field
                  v-model="qtyB"
                  label="Jumlah"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div class="col-md-4 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="tipeB"
                  :items="ContentType"
                  item-text="label"
                  item-value="value"
                  label="Tipe Konten"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="formatB"
                  :items="filteredFormat"
                  item-text="label"
                  item-value="value"
                  label="Format Konten"
                  outlined
                  dense
                ></v-select>
              </div>
              <div class="col-md-3 pb-0 mb-0 pt-0 mt-0">
                <v-select
                  v-model="platformB"
                  :items="platform"
                  item-text="label"
                  item-value="value"
                  label="Platform"
                  outlined
                  dense
                ></v-select>
              </div>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="text-capitalize"
            @click="dialog = false"
          >
            Batal
          </v-btn>
          <v-btn
            v-if="dialogItem.name === 'Tambah Quotation'"
            color="primary"
            class="text-capitalize"
            @click="submit"
          >
            Simpan
          </v-btn>
          <v-btn 
            v-if="dialogItem.name === 'Ubah Quotation'"
            color="primary"
            class="text-capitalize"
            @click="submitEdit"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOG LOADING -->
    <v-dialog
      v-model="loadingSave"
      persistent
      max-width="350"
    >
      <v-card>
        <div class="d-flex justify-center pa-10">
          <v-progress-circular
            v-if="!success"
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <div v-else>
            <v-alert type="success">
              {{ dialogMessage }} <button style="float:right" type="button" v-on:click="loadingSave = false" class="close right ml-5" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </v-alert>
            <!-- <i class="fas fa-check-circle"></i> -->
          </div>
        </div>
      </v-card>
    </v-dialog>

    <!-- DIALOG DELETE -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Anda yakin hapus quotation ini ?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" class="text-capitalize" @click="dialogDelete = false">Batal</v-btn>
          <v-btn color="primary" class="text-capitalize" @click="deleteItem">Hapus</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props:['dataTable'],
  setup() {
    return {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        isLoading:true,
        loadingTable:true,
        loadingClient:true,
        loadingSave:false,
        success:false,
        dialog:false,
        dialogItem : {
          'name':'',
          'id':''
        },
        dialogDelete:false,
        dialogMessage:'',
        client:[],
        ContentType: [
          {label:'Review Produk', value:'review-product'},
          {label:'Paid Promote', value:'paid-promote'},
          {label:'Attendance', value:'attendance'},
          {label:'Link on Bio', value:'link-bio'},
        ],
        ContentFormat: [
          {label:'Story', value:'story'},
          {label:'Feed Photo', value:'feed-photo'},
          {label:'Feed Video', value:'feed-video'},
          {label:'Link (Website)', value:'link-bio'}
        ],
        platform: [
          {label:'Instagram', value:'instagram'},
          {label:'Tiktok', value:'tiktok'},
        ],
        quoName:null,
        clientName:null,
        qtyA:0,
        tipeA:null,
        formatA:null,
        platformA:null,
        qtyB:0,
        tipeB:null,
        formatB:null,
        platformB:null,
        idDelete:''
    }
  },
  mounted() {
    this.getClient()
    if(this.dataTable){
      this.loadingTable = false
    }
  },
  computed: {
      headers() {
        return [
          {text: 'No.', value: 'num', sortable: false, align: 'center', width: '10'},
          {text: 'User', value: 'quotation_user.business_name', sortable: false, align: 'center'},
          {text: 'Name', value: 'quotation.name', sortable: false, align: 'center'},
          {text: 'Influencer', value: 'influencer', sortable: false, align: 'center'},
          {text: 'Status', align: 'center', sortable: false, value: 'quotation.status',  width: '10'},
          {text: 'Aksi', align: 'right', sortable: false, value: 'action',},
        ]
      },
      filteredFormat(){
        if(this.selectType === 'link-bio'){
          return this.ContentFormat.filter(item => item.value === 'link-bio')
        } else {
          return this.ContentFormat.filter(item => item.value != 'link-bio')
        }
      }
    },
  methods: {
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    gotToDetail(item){
      this.$router.push({name:'quotation-detail', query: {item: this.encryptData(item)}}); 
    },
    openDialog(type, id){
      if(type === 'insert'){
        this.dialogItem.name = 'Tambah Quotation'
        this.dialog = true
      } else if (type === 'delete'){
        this.dialogDelete = true
        this.idDelete = id
      } else {
        this.loadQuotation(id)
        this.dialogItem.id = id
        this.dialogItem.name = 'Ubah Quotation'
        this.dialog = true
      }
      
    },
    getClient(){
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'users/getClient', {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            let client = response.data.data
            for(let a = 0; a < client.length; a++){
              let arrayClient = {
                'label': client[a].business_name,
                'value': client[a].id
              }
              this.client.push(arrayClient)
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    submit(){
      this.dialog = false;
      this.loadingSave = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'quotation', {
          name:this.quoName, 
          user_id:this.clientName,
          type_a:this.tipeA,
          type_b:this.tipeB,
          format_a: this.formatA,
          format_b:this.formatB,
          platform_a: this.platformA,
          platform_b: this.platformB,
          qty_a: this.qtyA,
          qty_b: this.qtyB
        }, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 201){
            setTimeout(() => { 
              this.dialogMessage = 'Berhasil Tersimpan'
              this.success = true
              this.refreshQuotation()
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    submitEdit(){
      this.dialog = false;
      this.loadingSave = true;
      let token = localStorage.getItem('token')
      axios.patch(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+this.dialogItem.id, {
          name:this.quoName, 
          user_id:this.clientName,
          type_a:this.tipeA,
          type_b:this.tipeB,
          format_a: this.formatA,
          format_b:this.formatB,
          platform_a: this.platformA,
          platform_b: this.platformB,
          qty_a: this.qtyA,
          qty_b: this.qtyB
        }, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            setTimeout(() => { 
              this.dialogMessage = 'Berhasil Tersimpan'
              this.success = true
              this.refreshQuotation()
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    deleteItem(){
      this.dialogDelete = false;
      this.loadingSave = true;
      let token = localStorage.getItem('token')
      axios.delete(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+this.idDelete, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            setTimeout(() => { 
              this.dialogMessage = 'Berhasil Terhapus'
              this.success = true
              this.refreshQuotation()
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadQuotation(id){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'quotation/'+id, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            this.itemData = response.data.data
            for(let a = 0; a < this.itemData.length; a++){
              this.quoName = this.itemData[a].name
              this.clientName = this.itemData[a].user_id
              if(this.itemData[a].level === 'A'){
                this.qtyA = parseInt(this.itemData[a].qty);
                this.formatA = this.itemData[a].format
                this.tipeA = this.itemData[a].type
                this.platformA = this.itemData[a].platform
              } else {
                this.qtyB = parseInt(this.itemData[a].qty);
                this.formatB = this.itemData[a].format
                this.tipeB = this.itemData[a].type
                this.platformB = this.itemData[a].platform
              }
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    refreshQuotation(){
      this.$emit('eventValue', true);
    },
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  }
}
</script>