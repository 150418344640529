<template>
  <v-row>
    <v-col cols="12">
        <list-quotation></list-quotation>
    </v-col>
  </v-row>
</template>

<script>
import ListQuotation from './components/ListQuotation.vue'

import VueJwtDecode from 'vue-jwt-decode'

export default {
  components: {
    ListQuotation
  },
  setup() {
    return {}
  },
  mounted(){
    let token = VueJwtDecode.decode(localStorage.getItem('token'))
    let role = token.role
    if(role != 'admin'){
      this.$router.push({name:'error-404'}); 
    }
  },
  methods:{
    
  }
}
</script>
